<template>
  <section id="contacts" class="contacts">
    <iframe
      id="your-iframe-id"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A19e40561bbc724014f390f7f94b192ee1737837e14b9381333835818c7c847e3&amp;source=constructor"
      width="100%"
      height="400"
      ref="iframe"
      frameborder="0"
    ></iframe>
    <div class="contacts__info">
      <h3 class="contacts__info-title">Адрес офиса:</h3>
      <p class="contacts__info-text">
        Москва, (м. Шоссе Энтузиастов) ул.&nbsp;Плеханова&nbsp;9,
        1&nbsp;этаж (направо) офис&nbsp;33
      </p>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('message', this.handleIframeMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeMessage, false);
  },
  methods: {
    handleIframeMessage(event) {
      const iframe = this.$refs.iframe;
      if (!iframe || !iframe.contentWindow) {
        console.error('Iframe or contentWindow is not available');
        return;
      }

      if (event.source !== iframe.contentWindow) {
        return;
      }

      if (event.data && event.data.height) {
        iframe.style.height = `${event.data.height}px`;
      }
    },
  },
};
</script>
