<template>
    <HeaderBlock/>
    <TeaserBlock/>
    <VacancyBlock/>
    <BenefitsBlock/>
    <ApplicationBlock/>
    <ContactBlock/>
</template>

<script>
import HeaderBlock from './components/HeaderBlock.vue';
import TeaserBlock from './components/TeaserBlock.vue';
import VacancyBlock from './components/VacancyBlock.vue';
import BenefitsBlock from './components/BenefitsBlock.vue';
import ApplicationBlock from './components/ApplicationBlock.vue';
import ContactBlock from './components/ContactBlock.vue';

export default {
  name: 'App',
  components: {
    HeaderBlock,
    TeaserBlock,
    VacancyBlock,
    BenefitsBlock,
    ApplicationBlock,
    ContactBlock
  },
  mounted() {
    this.createSnowflakes();
  },
  methods: {
    createSnowflakes() {
      const container = document.querySelector('#app');
      const numberOfSnowflakes = 50;

      for (let i = 0; i < numberOfSnowflakes; i++) {
        const snowflake = document.createElement('div');
        snowflake.className = 'snowflake';
        snowflake.style.left = `${Math.random() * 100}vw`;
        snowflake.style.animationDuration = `${Math.random() * 2 + 5}s`;
        snowflake.style.animationDelay = `${Math.random() * 3}s`;
        container.appendChild(snowflake);
      }
    }
  }
};
</script>

<style>
  #app {
    position: relative;
    overflow: visible; /* Позволяем прокрутку */
  }

  .snowflake {
    position: fixed; /* Используем fixed, чтобы снежинки падали по всей высоте страницы */
    width: 20px; /* Увеличим размер снежинок */
    height: 20px; /* Увеличим размер снежинок */
    background: url('@/assets/snowflake.png') no-repeat center center;
    background-size: contain;
    opacity: 0.8;
    top: -20px; /* Начнем снежинки сверху */
    z-index: 9999;
  }

  @media screen and (max-width: 768px) {
    .snowflake {
      width: 10px;
      height: 10px;
    }
  }

  @keyframes fall {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100vh);
    }
  }

  .snowflake {
    animation: fall linear infinite;
  }
</style>
