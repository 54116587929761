<template>
  <section class="teaser">
    <div class="teaser__wrapper wrapper">
      <div class="teaser__block">
        <h1 class="teaser__title">
          Работа в Москве с проживанием и питанием
        </h1>
        <p class="teaser__text">
          Вахта для женщин и мужчин в Москве с комфортным проживанием и вкусным питанием
        </p>
        <button class="teaser__btn btn" @click="openModal">
          Заказать звонок
        </button>
      </div>
      <modal :visible="showModal" @close="closeModal" :title='title' :btn='btn'></modal>
    </div>
  </section>
</template>

<script>
import Modal from "../ui/ModalBlock.vue";

export default {
  name: "ParentComponent",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      title: "Оставьте заявку, мы перезвоним и ответим на все ваши вопросы",
      btn: "Перезвоните мне",
    };
  },
  mounted() {
    // Ваш код для mounted
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.createFireworks();
    },
    closeModal() {
      this.showModal = false;
    },
    createFireworks() {
      const numberOfFireworks = 50;
      const container = document.body; // Используем body для добавления салюта
      const colors = ['rgb(255, 0, 0)', 'rgb(0, 255, 0)', 'rgb(0, 0, 255)', 'rgb(255, 255, 0)', 'rgb(255, 0, 255)'];

      for (let i = 0; i < numberOfFireworks; i++) {
        const firework = document.createElement('div');
        firework.className = 'firework';
        firework.style.left = `${Math.random() * 100}vw`;
        firework.style.top = `${Math.random() * 100}vh`;
        firework.style.animationDelay = `${Math.random()}s`;
        firework.style.background = `radial-gradient(circle, ${colors[Math.floor(Math.random() * colors.length)]} 0%, rgba(255,255,255,0) 100%)`;
        container.appendChild(firework);

        // Удалим элемент после завершения анимации
        setTimeout(() => {
          container.removeChild(firework);
        }, 3000);
      }
    }
  },
};
</script>

<style>
  .firework {
    position: fixed;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0;
    animation: firework-animation 2s ease-out forwards;
    z-index: 10000;
  }

  @keyframes firework-animation {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
</style>
